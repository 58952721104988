import React from "react"
import SEO from "../../components/seo"
import HeroFeaturePage from "../../components/pages/shared/hero/feature-page"
import FeaturesComponent from "../../components/pages/main/landing-features"
import ContactFormComponent from "../../components/pages/shared/contact-form"
import FeatureAccordion from "../../components/pages/main/accordion"
import Layout from "../../components/layout"
import BannerComponent from "../../components/pages/features/banner"
import GalleryComponent from "../../components/pages/features/gallery"
import FeaturesRow from "../../components/pages/features/row"
import FeaturesPricingCards from "../../components/pages/features/cards"
import Discount from "../../components/pages/main/discount"
import Breadcrumb from '../../components/controls/breadcrumbs'

const portalEducationAccordionData = [
  {
    name: "schedule",
    title: "Как клиентам посмотреть свое расписание через личный кабинет?",
    content: `
      <p>Все занятия, как прошедшие, так и будущие, клиент увидит во вкладке “Расписание” в своем кабинете. Если урок перенесено или отменено администрацией центра, это отобразится в расписании клиента.</p>
    `,
  },
  {
    name: "checkLesson",
    title: "Как родителю проверить, был ли ребенок на занятии, используя личный кабинет?",
    content: `
      <p>Для того, чтобы быть уверенным, что ребенок 100% посетил занятие, родителю не нужно обрывать телефон педагогу. Требуется только зайти в свой личный кабинет от CRM-системы и перейти во вкладку “Посещения”. Если ребенок пропустил занятие, это будет указано. Через персональный кабинет родитель может отслеживать статусы посещения любых занятий, контролировать списания занятий с абонемента.</p>
    `,
  },
  {
    name: "homeWork",
    title: "Отображается ли домашнее задание в личном кабинете студента/ученика?",
    content: `
      <p>Ученик или родитель увидит домашнее задание, ссылку на вебинар в карточке посещения занятия в своем кабинете. Также он получит письмо с д/з на электронную почту, привязанную к кабинету, если педагог или администратор детского центра указал это в настройках.</p>
    `,
  }
]

const PortalFeaturePage = (props) => {
  const crumbs = [
    { name: 'Главная', url: '/'},
    { name: 'Возможности', url: '/feature'},
    { name: 'Кабинет клиента', url: ''}
  ];
  return (
    <Layout page={"portal"}>
      <SEO
        title="Личные кабинеты | CRM-система учета для детских центров"
        keywords={[`зарплаты сотрудников`, `расчет зарплат педагогов`, `расчет зарплат администраторов`]}
        description="В CRM для детских и учебных центров Параплан есть Личные кабинеты учеников. Индивидуальное расписание, онлайн оплаты, домашние задания для каждого ученика. Облачная CRM Параплан подходит для детских центров, франшиз, танцевальных студий, языковых центров, учебных центров и спортивных школ."
        url={"https://paraplancrm.ru" + props.location.pathname}
      />
      <HeroFeaturePage
        name={"portal"}
        title='Оформите клиентам &nbsp; личный кабинет в CRM'
        description='Расписание, онлайн оплата и домашние задания теперь под&nbsp;рукой у ваших клиентов.'
        color='#6D20D2'
        image={require("../../images/cover/portal.png")} />
      <Breadcrumb crumbs={ crumbs } arrowcolor={"purple"}/>
      <GalleryComponent
        title={"Личный кабинет для каждого ученика"}
        galleryView={"video"}
        data={[
          {
            name: "schedule",
            title: "Удобное расписание",
            content: "Ученики могут проверить свое расписание в 2 клика и поставить напоминание о занятии в CRM с кабинетом клиента.",
            image: require("../../images/screenshot/portal/portal_gallery_schedule@2x.png"),
            video: require("../../video/schedule.mp4"),
          },
          {
            name: "homework",
            title: "Домашние задания",
            content: "Добавляйте домашние задания или ссылки на вебинары для учеников. Они увидят их в личном кабинете. Ученики также получат уведомление на почту и смогут сразу приступить к просмотру.",
            image: require("../../images/screenshot/portal/portal_gallery_homework@2x.png"),
            video: require("../../video/homework.mp4"),
          },
          {
            name: "acquiring",
            title: "Онлайн-оплата",
            content: "Оплата абонементов легко осуществляется через личный кабинет. Теперь не надо тратить время администратора и искать наличные.",
            image: require("../../images/screenshot/portal/portal_gallery_acquiring@2x.png"),
            video: require("../../video/acquiring.mp4"),
          },
          {
            name: "finances",
            title: "Финансовая прозрачность",
            content: "В личном кабинете клиенты видят историю платежей и неоплаченных абонементов.",
            image: require("../../images/screenshot/portal/portal_gallery_finances@2x.png"),
            video: require("../../video/finances.mp4"),
          },
        ]}
      />
      {/*<FreeSummer topInd="60"/>*/}
      <Discount />
      <FeaturesRow
        features={[
          {
            name: "Проверка баланса ученика",
            text: "Положительный или отрицательный баланс доступен для просмотра и пополнения.",
            image: require("../../images/icons/portal_balance.svg"),
          },
          {
            name: "История посещений клиента",
            text: "Студент видит все состоявшиеся занятия со своим участием.",
            image: require("../../images/icons/portal_attendance.svg"),
          },
          {
            name: "Отмены занятий в расписании",
            text: "Все изменения в расписании наглядно отображаются внутри кабинета.",
            image: require("../../images/icons/portal_cancellations.svg"),
          },
        ]} />
      <BannerComponent color={"green"} author={"Развивающий центр «Чиполлино»"} ctaText={"Подключить личные кабинеты"}>
        “Мы подключили личные кабинеты несколько месяцев назад. Через них наши клиенты оплачивают посещения и получают
        задания. Это оказалось очень легко и удобно. Спасибо команде Параплан, что делаете нашу жизнь проще!”
      </BannerComponent>
      <FeaturesPricingCards
        withMargins={true}
        title='Станьте ближе к своим клиентам!'
        subtitle='Стоимость функции "Личные кабинеты" вне зависимости от количества учеников.' />
      <FeaturesComponent title="Дополнительные возможности" />
      <ContactFormComponent />
      <FeatureAccordion
            data={portalEducationAccordionData}
            title='Преимущества личного кабинета студента в CRM для образования'
            descr='Личный кабинет - это отдельный кабинет ученика, где есть вся необходимая информация. Личные кабинеты студентов можно проверять как с телефона, так и с компьютера. Иконку персонального кабинета клиент может добавить на рабочий стол телефона. Домашнее задание, посещения, история оплат и списания за абонементы, ближайшие занятия, оценки - всё необходимое ученику или родителю в одном месте. Без дополнительной нагрузки на преподавателя.
            В CRM-системе для детских центров Параплан мы предлагаем подключить каждому клиенту кабинет, чтобы поддержать лояльность клиентов. Более того, взаимодействие с клиентами через личные кабинеты помогает увеличивать продажи и удерживать клиентов в вашем центре.
            '
      />
    </Layout>
  )
}

export default PortalFeaturePage
